import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import pages from '../../config/pages';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const isLoggedIn = useSelector(state => state.auth.login);

  return (
    <Route {...rest} render={props => (isLoggedIn ? <Component {...props} /> : <Redirect to={`/${pages.login}`} />)} />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  path: PropTypes.string,
};

export default ProtectedRoute;

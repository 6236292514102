import React from 'react';
import { Card } from 'antd';
import loginIMG from '../../../static/img/login-img.svg';
import enLogoImage from '../../../static/img/workano-dark-en.svg';
import faLogoImage from '../../../static/img/workano-dark-fa.svg';
import strings from '../../../utility/strings';
import config from '../../../config/config';
import LanguageSwitch from '../../Admin/comps/LanguageSwitch';

export default function AuthContainer({ children }) {
  return (
    <div className="auth-container">
      <Card className="login-card">
        <div className="login-card-side" style={{ backgroundColor: '#081A51' }}>
          <img src={loginIMG} />
          <h1>{strings().adminPanel}</h1>
        </div>
        <div className="login-card-main">
          <LanguageSwitch className="lang-switch" />
          <img src={config.currentLang.culture === 'fa-IR' ? faLogoImage : enLogoImage} className="logo" />
          {children}
          <div className="copyright">
            <small>Copyright - Workano Inc.</small>
          </div>
        </div>
      </Card>
    </div>
  );
}

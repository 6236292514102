import React from 'react';
import { NavLink } from 'react-router-dom';
import { ErrorWrapper } from './style';
import { Main } from '../styled';
import Heading from '../../components/heading/heading';
import { Button } from '../../components/buttons/buttons';
import NotFoundImage from '../../static/img/pages/404.svg'; // ES6 import syntax
import strings from '../../utility/strings';
import pages from '../../config/pages';

function NotFound() {
  return (
    <Main>
      <ErrorWrapper>
        <img src={NotFoundImage} alt="404" />
        <Heading className="error-text" as="h3">
          404
        </Heading>
        <p>{strings()[404]}</p>
        <NavLink to={pages.dashboard}>
          <Button size="default" type="primary" to="/">
            {strings().returnHome}
          </Button>
        </NavLink>
      </ErrorWrapper>
    </Main>
  );
}

export default NotFound;

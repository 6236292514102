import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import pages from '../../config/pages';

const PublicRoute = ({ component: Component, ...rest }) => {
  const isLoggedIn = useSelector(state => state.auth.login);

  return (
    <Route
      {...rest}
      render={props => (isLoggedIn ? <Redirect to={`/${pages.dashboard}`} /> : <Component {...props} />)}
    />
  );
};

PublicRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default PublicRoute;

import Styled from 'styled-components';
import { Row } from 'antd';

const RowStyled = Styled(Row)`
    .ant-alert-message {
        font-size:14px!important;
        font-weight:600;
    }
    .ant-alert-description{
        font-size:12px!important;
        text-align:justify;
    }
    .btn-confirm{
        height:36px;
    }
    .actions{
        text-align:left;
    }
`;

export { RowStyled };

import React from 'react';
import { Menu } from 'antd';
import { NavLink, useHistory } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import strings from '../utility/strings';
import pages from '../config/pages';

const { SubMenu } = Menu;

const MenuItems = ({ darkMode, toggleCollapsed, topMenu }) => {
  // const { path } = useRouteMatch();
  const history = useHistory();
  const items = React.useMemo(() => [
    {
      key: pages.dashboard,
      title: strings().menus.dashboard,
      path: `/${pages.dashboard}`,
      icon: 'bar-chart-2',
    },
    {
      key: pages.tenant,
      title: strings().menus.tenantAdmin,
      path: `/${pages.tenant}`,
      icon: 'user',
    },
    {
      key: 'users',
      title: strings().menus.userManagement,
      icon: 'users',
      childs: [
        {
          key: pages.users,
          title: strings().menus.users,
          path: `/${pages.users}`,
          icon: 'users',
        },
        // {
        //   key: pages.authUsers,
        //   title: strings().menus.authUsers,
        //   path: `/${pages.authUsers}`,
        //   icon: 'users',
        // },

        // {
        //   key: pages.context,
        //   title: strings().menus.contextAdmin,
        //   path: `/${pages.context}`,
        //   icon: 'shuffle',
        // },
        // {
        //   key: pages.extensions,
        //   title: strings().menus.extesnionAdmin,
        //   path: `///${pages.extensions}`,
        //   icon: 'shuffle',
        // },
        // {
        //   key: pages.sipTemplate,
        //   title: strings().menus.sipTemplateAdmin,
        //   path: `///${pages.sipTemplate}`,
        //   icon: 'clipbord',
        // },
        // {
        //   key: pages.sipTransport,
        //   title: strings().menus.sipTransportAdmin,
        //   path: `///${pages.sipTransport}`,
        //   icon: 'clipbord',
        // },
        {
          key: pages.voicemails,
          title: strings().menus.voicemails,
          path: `/${pages.voicemails}`,
          icon: 'volume-2',
        },
        // {
        //   key: pages.providerManagement,
        //   title: strings().providerManagement.providerManagement,
        //   path: `/${pages.providerManagement}`,
        //   icon: 'triangle',
        // },
        // {
        //   key: pages.lines,
        //   title: strings().lines.lines,
        //   path: `///${pages.lines}`,
        //   icon: 'voicemail',
        // },

        // {
        //   key: pages.sessions,
        //   title: strings().menus.sessions,
        //   path: `///${pages.sessions}`,
        // },
        // {
        //   key: pages.funckeys,
        //   title: strings().menus.funckeys,
        //   path: `/${pages.funckeys}`,
        //   icon: 'command',
        // },
        // {
        //   key: pages.resellers,
        //   title: strings().menus.resellers,
        //   path: `///${pages.resellers}`,
        // },
        // {
        //   key: pages.customers,
        //   title: strings().menus.customers,
        //   path: `///${pages.customers}`,
        // },
        // {
        //   key: pages.resellerContacts,
        //   title: strings().menus.resellerContacts,
        //   path: `///${pages.resellerContacts}`,
        // },
        // {
        //   key: pages.customerContacts,
        //   title: strings().menus.customerContacts,
        //   path: `///${pages.customerContacts}`,
        // },
        // {
        //   key: pages.resellerAddresses,
        //   title: strings().menus.resellerAddresses,
        //   path: `///${pages.resellerAddresses}`,
        // },
        // {
        //   key: pages.customerAddresses,
        //   title: strings().menus.customerAddresses,
        //   path: `///${pages.customerAddresses}`,
        // },
        // {
        //   key: pages.contactAddresses,
        //   title: strings().menus.contactAddresses,
        //   path: `///${pages.contactAddresses}`,
        // },
      ],
    },
    {
      key: 'callManagement',
      title: strings().menus.callManagement,
      icon: 'phone-call',
      childs: [
        {
          key: pages.incalls,
          title: strings().menus.incalls,
          path: `/${pages.incalls}`,
          icon: 'phone-incoming',
        },
        {
          key: pages.outcalls,
          title: strings().menus.outcalls,
          path: `/${pages.outcalls}`,
          icon: 'phone-outgoing',
        },

        {
          key: pages.trunk,
          title: strings().menus.truncAdmin,
          path: `/${pages.trunk}`,
          icon: 'server',
        },
      ],
    },

    {
      key: 'meetings',
      title: strings().menus.meetings,
      icon: 'video',
      childs: [
        {
          key: pages.meetings,
          title: strings().meetings.meetings,
          path: `/${pages.meetings}`,
          icon: 'aperture',
        },
        // {
        //   key: pages.ingresses,
        //   title: strings().ingresses.ingresses,
        //   path: `/${pages.ingresses}`,
        //   icon: 'settings',
        // },
      ],
    },

    {
      key: 'features',
      title: strings().menus.features,
      icon: 'pocket',
      childs: [
        {
          key: pages.schedules,
          title: strings().schedule.schedules,
          path: `/${pages.schedules}`,
          icon: 'calendar',
        },
        {
          key: pages.callPermissions,
          title: strings().callPermissions.callpermissions,
          path: `/${pages.callPermissions}`,
          icon: 'pocket',
        },
        {
          key: pages.callPickups,
          title: strings().menus.callPickups,
          path: `/${pages.callPickups}`,
          icon: 'phone',
        },

        {
          key: pages.blackList,
          title: strings().menus.blackList,
          path: `/${pages.blackList}`,
          icon: 'list',
        },
        {
          key: pages.bSFilters,
          title: strings().menus.bsFillters,
          path: `/${pages.bSFilters}`,
          icon: 'filter',
        },
        // {
        //   key: pages.visualBuilder,
        //   title: strings().menus.visualBuilder,
        //   path: `/${pages.visualBuilder}`,
        //   icon: 'git-pull-request',
        // },
      ],
    },
    {
      key: pages.conferences,
      title: strings().context.conference,
      icon: 'mic',
      childs: [
        {
          key: pages.conferences,
          title: strings().context.conference,
          path: `/${pages.conferences}`,
          icon: 'mic',
        },
        {
          key: pages.ringGroups,
          title: strings().menus.ringGroups,
          path: `/${pages.ringGroups}`,
          icon: 'airplay',
        },
      ],
    },
    {
      key: 'services',
      title: strings().menus.services,
      icon: 'box',
      childs: [
        {
          key: pages.applications,
          title: strings().menus.applications,
          path: `/${pages.applications}`,
          icon: 'square',
        },
        {
          key: pages.pagings,
          title: strings().menus.pagings,
          path: `/${pages.pagings}`,
          icon: 'layout',
        },
        {
          key: pages.parkingLots,
          title: strings().menus.parkingLots,
          path: `/${pages.parkingLots}`,
          icon: 'compass',
        },
        {
          key: pages.switchboards,
          title: strings().menus.switchboards,
          path: `/${pages.switchboards}`,
          icon: 'clipboard',
        },
        {
          key: pages.webhooks,
          title: strings().menus.webhooks,
          path: `/${pages.webhooks}`,
          icon: 'cloud-lightning',
        },
        {
          key: pages.ivrs,
          title: strings().menus.ivrs,
          path: `/${pages.ivrs}`,
          icon: 'headphones',
        },
      ],
    },
    {
      key: pages.cdr,
      title: strings().menus.cdr,
      icon: 'pie-chart',
      childs: [
        {
          key: pages.totalCDR,
          title: strings().menus.totalCDR,
          path: `/${pages.totalCDR}`,
          icon: 'bar-chart-2',
        },
        {
          key: pages.agentsCDR,
          title: strings().menus.agentsCDR,
          path: `/${pages.agentsCDR}`,
          icon: 'bar-chart',
        },
        /* {
          key: pages.queuesCDR,
          title: strings().menus.queuesCDR,
          path: `///${pages.queuesCDR}`,
        }, */
      ],
    },
    // {
    //   key: 'documents',
    //   title: strings().menus.documents,
    //   icon: 'file-text',
    //   childs: [
    //     {
    //       key: pages.resellerDocuments,
    //       title: strings().menus.resellerDocuments,
    //       path: `///${pages.resellerDocuments}`,
    //     },
    //     {
    //       key: pages.customerDocuments,
    //       title: strings().menus.customerDocuments,
    //       path: `///${pages.customerDocuments}`,
    //     },
    //   ],
    // },

    {
      key: 'callCenter',
      title: strings().menus.callCenter,
      icon: 'headphones',
      childs: [
        {
          key: pages.agents,
          title: strings().menus.agents,
          path: `/${pages.agents}`,
          icon: 'user-check',
        },
        {
          key: pages.queues,
          title: strings().menus.queues,
          path: `/${pages.queues}`,
          icon: 'aperture',
        },
        {
          key: pages.survey,
          title: strings().menus.Survey,
          path: `/${pages.survey}`,
          icon: 'message-circle',
        },
        {
          key: pages.queueFeatures,
          title: strings().menus.queueFeatures,
          path: `/${pages.queueFeatures}`,
          icon: 'git-pull-request',
        },
        {
          key: pages.skillrules,
          title: strings().menus.skillrules,
          path: `/${pages.skillrules}`,
          icon: 'terminal',
        },
        {
          key: pages.skills,
          title: strings().menus.skills,
          path: `/${pages.skills}`,
          icon: 'code',
        },
        {
          key: pages.tags,
          title: strings().menus.tags,
          path: `/${pages.tags}`,
          icon: 'tag',
        },
        // {
        //   key: pages.callInfos,
        //   title: strings().menus.callInfos,
        //   path: `/${pages.callInfos}`,
        //   icon: 'info',
        // },
      ],
    },
    // {
    //   key: 'directory',
    //   title: strings().menus.directory,
    //   icon: 'folder',
    //   childs: [
    //     {
    //       key: pages.directoryProfile,
    //       title: strings().menus.directoryProfile,
    //       path: `/${pages.directoryProfile}`,
    //       icon: 'file-plus',
    //     },
    //     {
    //       key: pages.directorySources,
    //       title: strings().menus.directorySources,
    //       path: `/${pages.directorySources}`,
    //       icon: 'file-text',
    //     },
    //   ],
    // },
    {
      key: 'phonebooks',
      title: strings().menus.phonebooks,
      icon: 'book',
      childs: [
        {
          key: pages.phonebookConfigurations,
          title: strings().menus.phonebookConfigurations,
          path: `/${pages.phonebookConfigurations}`,
          icon: 'book',
        },
        {
          key: pages.phonebookContacts,
          title: strings().menus.phonebookContacts,
          path: `/${pages.phonebookContacts}`,
          icon: 'bookmark',
        },
      ],
    },
    // {
    //   key: pages.phoneServiceProviders,
    //   title: strings().menus.phoneServiceProviders,
    //   path: `/${pages.phoneServiceProviders}`,
    //   icon: 'server',
    // },
    {
      key: pages.wizard,
      title: strings().menus.wizard,
      icon: 'square',
      childs: [
        {
          key: pages.wizardTabs,
          title: strings().menus.tabWizard,
          path: `/${pages.wizardTabs}`,
          icon: 'square',
        },
        {
          key: pages.wizard,
          title: strings().menus.userWizard,
          path: `/${pages.wizard}`,
          icon: 'square',
        },
        {
          key: pages.contextWizard,
          title: strings().menus.contextWizard,
          path: `/${pages.contextWizard}`,
          icon: 'square',
        },
        {
          key: pages.incallWizard,
          title: strings().menus.incallWizard,
          path: `/${pages.incallWizard}`,
          icon: 'square',
        },
        {
          key: pages.outcallWizard,
          title: strings().menus.outcallWizard,
          path: `/${pages.outcallWizard}`,
          icon: 'square',
        },
        {
          key: pages.trunkWizard,
          title: strings().menus.trunkWizard,
          path: `/${pages.trunkWizard}`,
          icon: 'square',
        },
        {
          key: pages.queuesWizard,
          title: strings().menus.queuesWizard,
          path: `/${pages.queuesWizard}`,
          icon: 'square',
        },
        {
          key: pages.ivrWizard,
          title: strings().menus.ivrs,
          path: `/${pages.ivrWizard}`,
          icon: 'square',
        },
      ],
    },
    {
      key: 'soundsandgreetings',
      title: strings().menus.soundsandgreetings,
      icon: 'volume-2',
      childs: [
        {
          key: pages.musics,
          title: strings().menus.musics,
          path: `/${pages.musics}`,
          icon: 'volume-1',
        },
        {
          key: pages.soundfiles,
          title: strings().menus.soundfiles,
          path: `/${pages.soundfiles}`,
          icon: 'volume-1',
        },
      ],
    },
    // {
    //   key: pages.globalSettings,
    //   title: strings().menus.globalSettings,
    //   path: `///${pages.globalSettings}`,
    //   icon: 'settings',
    // },
  ]);

  const page = (() => {
    const current = window.location.pathname.split('/').reverse()[0];
    let parent = null;
    for (let i = 0; i < items.length; i++) {
      if (items[i].childs)
        for (let j = 0; j < items[i].childs.length; j++) {
          if (items[i].childs[j].key === current) {
            parent = items[i].key;
            break;
          }
        }
      else if (items[i].key === current) {
        parent = null;
        break;
      }
    }
    return { parent, current };
  })();

  const [openKeys, setOpenKeys] = React.useState(!topMenu ? (page.parent ? [page.parent] : []) : []);
  const onOpenChange = keys => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = item => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={!topMenu ? [page.current] : []}
      defaultOpenKeys={!topMenu ? [page.parent] : []}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
    >
      {items.map((m, idx) =>
        m.childs ? (
          <SubMenu className={m.key} key={m.key} icon={!topMenu && <FeatherIcon icon={m.icon} />} title={m.title}>
            {m.childs.map((c, cIdx) => (
              <Menu.Item style={{ paddingRight: '20px !important' }} key={c.key}>
                <NavLink onClick={toggleCollapsed} to={c.path} style={{ display: 'flex', alignItems: 'center' }}>
                  <FeatherIcon icon={c.icon} />
                  <span style={{ marginLeft: '8px' }}>{c.title}</span>
                </NavLink>
              </Menu.Item>
            ))}
          </SubMenu>
        ) : (
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={m.path}>
                  <FeatherIcon icon={m.icon} />
                </NavLink>
              )
            }
            key={m.key}
          >
            <NavLink onClick={toggleCollapsed} to={m.path}>
              {m.title}
            </NavLink>
          </Menu.Item>
        ),
      )}
    </Menu>
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
};

export default MenuItems;

import React, { useEffect, useState } from 'react';
import { Select, Alert, Col, Button } from 'antd';
import { RowStyled } from './style';
import { useDispatch, useSelector } from 'react-redux';
import strings from '../../../../utility/strings';
import { setNewTenant } from '../../../../redux/tenant/actionCreator';
import { hideModal } from '../../../../redux/modal/actionCreator';

const { Option } = Select;

const TenantSelector = () => {
  const tenantState = useSelector(state => state.tenant);
  const dispatch = useDispatch();
  const [state, setState] = useState({
    selectedTenantUUID: '',
    errorMessage: '',
  });

  useEffect(() => {
    if (tenantState.tenants.length > 0 && !state.selectedTenantUUID) {
      const firstTenantUUID = tenantState.tenants[0].uuid;
      setState({ selectedTenantUUID: firstTenantUUID, errorMessage: '' });
      const firstTenant = tenantState.tenants.find(x => x.uuid === firstTenantUUID);
      if (firstTenant) dispatch(setNewTenant(firstTenant));
    }
  }, [tenantState.tenants, state.selectedTenantUUID, dispatch]);

  const onTenantChange = uuid => {
    const selectedTenant = tenantState.tenants.find(x => x.uuid === uuid);
    if (selectedTenant) {
      setState({ selectedTenantUUID: uuid, errorMessage: '' });
      dispatch(setNewTenant(selectedTenant));
    }
  };

  const closeModal = () => {
    if (state.selectedTenantUUID) {
      dispatch(hideModal());
      window.location.reload(); // Reload the page
    } else {
      setState(prevState => ({ ...prevState, errorMessage: strings().validation.required }));
    }
  };

  const style = {
    marginBottom: 16,
  };

  return (
    <RowStyled gutter={16}>
      <Col span={24} style={style}>
        <Alert
          message={strings().tenant.pleaseSelectTenant}
          description={strings().tenant.selectTenantMessage}
          type="info"
        />
      </Col>
      <Col span={18} className="selector-wrapper" style={style}>
        <Select
          value={state.selectedTenantUUID}
          style={{ width: 180 }}
          onChange={onTenantChange}
          title={strings().tenant.tenant}
          placeholder={strings().loading}
          loading={tenantState.loading}
        >
          {tenantState.tenants.map((t, idx) => (
            <Option key={idx} value={t.uuid}>
              {t.name}
            </Option>
          ))}
        </Select>
        <div hidden={!state.errorMessage} className="ant-form-item-explain-error color-error">
          {state.errorMessage}
        </div>
      </Col>
      <Col span={6} className="actions" style={style}>
        <Button
          type="primary"
          size="medium"
          className="btn-confirm"
          onClick={closeModal}
          disabled={tenantState.loading}
        >
          {strings().button.confirm}
        </Button>
      </Col>
    </RowStyled>
  );
};

export default TenantSelector;

import React, { useCallback } from 'react';
import { Button, Form, Input } from 'antd';
import strings from '../../../utility/strings';
import FeatherIcon from 'feather-icons-react';
import UserService from '../../../config/dataService/userService';
import pages from '../../../config/pages';
import { Link } from 'react-router-dom';

export default function ForgotPassword() {
  const handleSubmit = useCallback(async values => {
    try {
      const params = { email: values.email };
      await UserService.resetPassword(params);
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <>
      <h3 className="main-title">{strings().identity.welcome}</h3>
      <Form onFinish={handleSubmit}>
        <Form.Item name="email" rules={[{ message: strings().validation.required, required: true }]}>
          <Input placeholder={strings().email} prefix={<FeatherIcon icon="user" size={20} />} />
        </Form.Item>
        <Link to={pages.login}>
          <small className="forgot-pass">{strings().button.backToLogin}</small>
        </Link>
        <Button htmlType="submit" size="large">
          {strings().button.sendEmail}
        </Button>
      </Form>
    </>
  );
}

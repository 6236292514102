import { DataService } from './dataService';
import Addresses from './../api';
import keys from '../../utility/keys';
import { setItem, removeItem, getItem } from '../../utility/localStorageControl';
import UserService from './userService';
import store from '../../redux/store';
import { setUser } from '../../redux/user/actionCreator';
import { failedNotif, isAdmin } from '../../utility/utility';

export default class IdentityService {
  static async login(email, password) {
    try {
      const login = await DataService.post(
        Addresses().identity.login,
        { access_type: 'offline', expiration: 36000, client_id: 'workano_client' },
        {
          'Wazo-Session-Type': 'desktop',
        },
        {
          auth: {
            username: email,
            password,
          },
        },
      );

      const isAdminUser = isAdmin(login?.data?.acl);
      if (!isAdminUser) {
        failedNotif('شما دسترسی ندارید !');
        return null; // Return null or an appropriate value if the user is not an admin
      }

      if (login.data?.token) {
        setItem(keys.token, login.data.token);
        setItem(keys.refreshToken, login.data.refresh_token);
      } else {
        return null; // Return null or an appropriate value if there is no token
      }

      const user = await UserService.authGet(login.data.xivo_user_uuid);

      if (user.uuid) store.dispatch(setUser(user));

      return login;
    } catch (error) {
      // Handle the error and send a notification with the error message
      return Promise.reject(error);
    }
  }

  static async logOut() {
    const token = getItem(keys.token);
    const host = getItem(keys.host);

    if (token && host) {
      try {
        await DataService.delete(Addresses().identity.logout(token), {
          headers: {
            'Wazo-Session-Type': 'desktop',
          },
        });
        removeItem(keys.token);
        removeItem(keys.refreshToken);
        removeItem(keys.tenant);
        removeItem(keys.user);
        removeItem(keys.host);
        window.location.href = '/';
      } catch (error) {
        console.error('Failed to revoke token', error);
      }
    }
  }

  static async refreshToken() {
    try {
      const refreshToken = getItem(keys.refreshToken);
      const response = await DataService.post(
        Addresses().identity.login,
        {
          refresh_token: refreshToken,
          // access_type: 'offline',
          expiration: 36000,
          client_id: 'workano_client',
        },
        {
          'Wazo-Session-Type': 'desktop',
        },
      );
      if (response.data?.token) {
        setItem(keys.token, response.data.token);
        // removeItem(keys.refreshToken);
        // setItem(keys.refreshToken, response.data.refresh_token);
        return true;
      }
      this.logOut();
      return false;
    } catch (error) {
      this.logOut();
      return false;
    }
  }
}
